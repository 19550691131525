
import { PerfilAfiliado } from "@/services/PerfilAfiliadoService";
import { getService } from "@/services/UserService";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { MutationTypes } from "@/store/mutations";
import { clone } from "@/utils/object";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const loading = ref(true);
    const router = useRouter();
    const store = useStore();
    const perfis = ref<PerfilAfiliado[]>([]);

    onMounted(async () => {
      try {
        perfis.value = await getService().getPerfis();
        await store.dispatch(ActionTypes.LOAD_ME);
        // perfis.value.sort((p1, p2) => {
        //   if (p1.pp1pedido === p2.pp1pedido) {
        //     return 0;
        //   }
        //   return p1.pp1pedido > p2.pp1pedido ? 1 : -1;
        // });
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      async changePerfil(id: number | null) {
        const perfil = perfis.value.find((p) => p.id == id) || null;
        try {
          await getService().changePerfilUpgrade(perfil?.id || null);
          store.commit(
            MutationTypes.SET_PERFIL_NOVO,
            perfil ? clone(perfil) : null
          );
          perfil && router.push({ name: "loja" });
        } finally {
        }
      },
    };
  },
});
