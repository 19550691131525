<template>
  <div class="p-grid card" v-if="loading">
    <p-progress-spinner />
  </div>
  <div class="p-grid card" v-else>
    <div class="p-col-12">
      <h5>Escolha o seu pacote de benefícios:</h5>
    </div>

    <div class="p-col-12 p-md-6 perfil-card">
      <div class="p-d-flex p-flex-column">
        <img src="/img/pin/executivo.png" />
        <span>Compras com 50% de desconto</span>
        <span>100% de lucro na revenda</span>
        <span>8 níveis de bônus 1º pedido</span>
        <span>8 níveis de bônus unilevel</span>
        <span>8 níveis de bônus ativação</span>
        <span>Plano de carreira</span>
        <span>Participação nos lucros</span>
        <span>Premiação de viagens</span>
        <span>200 pontos de graduação</span>
        <span class="p-mt-2 p-mb-3">
          <small>à partir de</small>
          <br />
          <span style="font-size: 1.5em">R$ 330,00</span>
        </span>

        <span v-if="$store.state.novoPerfil?.id == 1">
          <small style="color: red">(você ainda não finalizou esta compra)</small>
          <br />
          <p-button
            class="p-mt-1"
            label="Desistir Deste Plano"
            @click="changePerfil(null)"
          />
        </span>
        <span v-else-if="$store.state.user?.perfil?.id == 1">
          <b>(plano atual)</b>
        </span>
        <span v-else>
          <p-button label="Selecionar" @click="changePerfil(1)" />
        </span>
      </div>
    </div>

    <div class="p-col-12 p-md-6 perfil-card">
      <div class="p-d-flex p-flex-column">
        <img src="/img/pin/consultor.png" />
        <span>Compras com 50% de desconto</span>
        <span>100% de lucro na revenda</span>
        <span>2 níveis de bônus 1º pedido</span>
        <span>2 níveis de bônus unilevel</span>
        <span>50 pontos de graduação</span>
        <span class="p-mt-2 p-mb-3">
          <small>à partir de</small>
          <br />
          <span style="font-size: 1.5em">R$ 132,00</span>
        </span>

        <span v-if="$store.state.novoPerfil?.id == 2">
          <small style="color: red">(você ainda não finalizou esta compra)</small>
          <br />
          <p-button
            class="p-mt-1"
            label="Desistir Deste Plano"
            @click="changePerfil(null)"
          />
        </span>
        <span v-else-if="$store.state.user?.perfil?.id == 2">
          <b>(plano atual)</b>
        </span>
        <span v-else>
          <p-button label="Selecionar" @click="changePerfil(2)" />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PerfilAfiliado } from "@/services/PerfilAfiliadoService";
import { getService } from "@/services/UserService";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/actions";
import { MutationTypes } from "@/store/mutations";
import { clone } from "@/utils/object";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const loading = ref(true);
    const router = useRouter();
    const store = useStore();
    const perfis = ref<PerfilAfiliado[]>([]);

    onMounted(async () => {
      try {
        perfis.value = await getService().getPerfis();
        await store.dispatch(ActionTypes.LOAD_ME);
        // perfis.value.sort((p1, p2) => {
        //   if (p1.pp1pedido === p2.pp1pedido) {
        //     return 0;
        //   }
        //   return p1.pp1pedido > p2.pp1pedido ? 1 : -1;
        // });
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      async changePerfil(id: number | null) {
        const perfil = perfis.value.find((p) => p.id == id) || null;
        try {
          await getService().changePerfilUpgrade(perfil?.id || null);
          store.commit(
            MutationTypes.SET_PERFIL_NOVO,
            perfil ? clone(perfil) : null
          );
          perfil && router.push({ name: "loja" });
        } finally {
        }
      },
    };
  },
});
</script>

<style scoped lang="scss">
.perfil-card {
  > div {
    border: 2px solid #e9ecef;
    border-radius: 7px;
    // margin: 0.5em;
    padding: 0 0.7em 0.7em;
    // cursor: pointer;
    position: relative;
    img {
      align-self: center;
      width: 190px;
    }
    > span {
      text-align: center;
      font-size: 1em;
      // padding-bottom: 0.3em;
    }
  }
}
.perfil-card-checked {
  border: 2px solid #007be5;
  background: #eef1f5;
}
</style>